import React, { useContext, useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';

import { useLazyQuery } from '../hooks/graph';
import { SetupContext } from '../contexts/setup';
import Spinner from '../components/spinner';
let controller; // StrictMode friendly

const INTERVALS = ['hour', 'day', 'week', 'month'];
const intervalsTicks = interval => interval === 'week' ? d3.timeFriday.every(1) : d3['time' + interval.charAt(0).toUpperCase() + interval.slice(1)].every(1);
const intervalsTicksLength = interval => ({hour: 24, day: 30, week: 8, month: 12}[interval]);

function MeDashboardActionListChart({ connectors }) {
	const { providers } = useContext(SetupContext);
	const [ getActionsOrderStats, { data, loading } ] = useLazyQuery('actionsOrderStats', controller);
	const [ state, setState ] = useState({interval: 'day', providerName: ''});
	const [ chartState, setChartState ] = useState();
	const ref = useRef();

	useEffect(() => {
		state && getActionsOrderStats({variables: state});
	}, [state]);

	useEffect(() => {
		setState(s => ({...s, providerNames: [...new Set(connectors.map(item => item.providerName))]}));
	}, [connectors]);

	useEffect(() => {
		ref.current && setState(s => ({...s, width: ref.current.clientWidth}));
	}, [ref.current]);

	// step by step guide:
	// https://observablehq.com/@stuartathompson/a-step-by-step-guide-to-the-d3-v4-stacked-bar-chart
	useEffect(() => {
		if (!data || !state.width) {
			return;
		}

		const timeTo = Date.now();
		const timeFrom = timeTo - {
			hour: 1, day: 30, week: 56, month: 365, // default period in days
		}[state.interval] * 24 * 3600 * 1000;

		const statsKeys = ['counterSuccess', 'counterError'];
		const statsKeysColors = {counterSuccess: 'hsl(from var(--bs-success) h s l / 0.3)', counterError: 'hsl(from var(--bs-danger) h s l / 0.3)'};
		const stats = data.actionsOrderStats.
			filter(item => item.createdAt >= timeFrom && item.createdAt <= timeTo).
			map(item => ({...item, counterSuccess: item.counter - item.counterError}));

		const width = ref.current.clientWidth;
		const height = 180;
		const marginTop = 10;
		const marginRight = 0;
		const marginBottom = 20;
		const marginLeft = 30;
		const svg = chartState || d3.select(ref.current);

		if (chartState) {
			svg.selectAll('g').remove();
			svg.selectAll('path').remove();
		}

		else {
			svg.append('svg').
				attr('viewBox', [0, 0, width, height]).
				attr('width', width).
				attr('height', height).
				attr('style', 'max-width: 100%; height: auto;');
		}

		if (!stats.length) {
			return;
		}

		const x = d3.scaleTime().domain([timeFrom, timeTo]).range([marginLeft, width - marginRight]);
		const y = d3.scaleLinear().domain([0, Math.max(...stats.map(d => d.counter))]).nice().rangeRound([height - marginBottom, marginTop]);

		const stack = d3.stack().keys(statsKeys)(stats);
		stack.map((d, i) => { d.map(dd => { dd.key = statsKeys[i]; return dd; }); return d; });

		// add x axis
		svg.append('g').
			attr('transform', `translate(0,${height - marginBottom})`).
			attr('class', 'text-muted').
			call(d3.axisBottom(x).
				ticks(intervalsTicks[state.interval]). // ex: d3.timeDay.every(1)
				tickFormat((date, i) => {
					switch (state.interval) {
						case 'hour':
							return d3.timeFormat('%H')(date);
							// reserved / return date.toLocaleDateString(undefined, i == 0 || date.getHour() == 1 ? {month: 'short', day: 'numeric'} : {day: 'numeric'});

						case 'day':
							return date.toLocaleDateString(undefined, i == 0 || date.getDate() == 1 ? {month: 'short', day: 'numeric'} : {day: 'numeric'});

						case 'week':
							return date.toLocaleDateString(undefined, {month: 'short', day: 'numeric'});

						case 'month':
							return date.toLocaleDateString(undefined, i == 0 || date.getMonth() == 0 ? {month: 'short', year: 'numeric'} : {month: 'short'});
					}
				}).
				tickSizeOuter(0)
			);

		// add y axis
		svg.append('g').
			attr('transform', `translate(${marginLeft},0)`).
			attr('class', 'text-muted').
			call(d3.axisLeft(y).ticks(5).tickFormat(d => d && Number.isInteger(d) ? d : '').tickSize(0)).
			call(g => g.select('.domain').remove());

		// add bars (ie data)
		svg.append('g').
			selectAll('g').
			data(stack).

			enter().append('g').
			attr('fill', d => statsKeysColors[d.key]).
			selectAll('rect').
			data(d => d).

			enter().append('rect').
			attr('x', (d, i) => x(stats[i].createdAt)).
			attr('y', d => y(d[1])).
			attr('width', () => width / intervalsTicksLength(state.interval) / 1.5). // weak
			attr('height', d => y(d[0]) - y(d[1])).
			attr('fill', d => statsKeysColors[d.key]);

		if (!chartState) {
			setChartState(svg);

			window.addEventListener('resize',
				() => setState(s => ({...s, width: ref.current?.clientWidth})),
				{signal: controller?.signal});
		}
	}, [data, state.width]);

	useEffect(() => { controller = new AbortController(); return () => controller.abort(); }, []); // abort on unmount // StrictMode friendly

	return (
		<div className="position-relative pt-1">

			{
				state.providerNames && (
					<div className="d-flex flex-row justify-content-center align-items-center mx-auto">
						<div>
							<h6 className="my-0 mx-2 text-secondary">Hook calls by</h6>
						</div>
						<div className="btn-group btn-group-sm opacity-75" style={{top: 0, right: 0}} role="group">
							{ INTERVALS.map(item => <button key={item} type="button" className={'btn ' + (item === state.interval ? 'btn-secondary' : 'btn-outline-secondary')} onClick={setState.bind(this, {...state, interval: item})}>{item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()}</button>) }
						</div>

						{
							state.providerNames.length > 1 && (
								<div className="px-2 d-none d-md-block">
									<select value={state.providerName} onChange={event => setState({...state, providerName: event.target.value})} name="orderType" id="orderType" className="form-select form-select-sm border-secondary text-secondary opacity-75">
										<option key="_" value="">All brokers</option>
										<option key="__" value="">- - -</option>
										{ state.providerNames.map(item => <option key={item} value={item}>{providers[item]?.title}</option>) }
									</select>
								</div>
							)
						}
					</div>
				)
			}

			<svg width={'100%'} height={180} className="mt-2" id="chart" ref={ref} />
			{ data?.actionsOrderStats && !data?.actionsOrderStats.length && <div className="position-absolute text-center text-secondary mt-2" style={{width: '100%', top: '50%'}}>No stats</div> }
			{ loading && <div className="position-absolute text-center text-secondary" style={{width: '100%', top: '50%'}}><Spinner show={true || loading} /></div> }
		</div>
	);
}

export default MeDashboardActionListChart;
