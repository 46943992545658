import React, { useState, useEffect, useContext } from 'react'; // TODO: remove useContext
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';

import config from '../config';
import { useQuery, useMutation } from '../hooks/graph';
import { SetupContext } from '../contexts/setup';
import Modal, { ModalBody, ModalHeader, ModalFooter } from '../components/modal';
import { formControlInvalid } from '../components/forms';
import Spinner from '../components/spinner';
import { setClipboard } from '../utils/misc';
import { ClipboardIcon } from '../utils/icons';

import MeConnectorCreate from './me-connector-create';
import MeConnectorDelete from './me-connector-delete';

const getHookUrl = shortKey => window.location.protocol + '//' + window.location.host + '/hook/' + shortKey;
const createdAtTo = Date.now() - 24 * 60 * 60 * 1000; // 24hr
const controller = new AbortController();

function MeConnector({ match }) {
	const { providerName: selectedProviderName } = match.params || {};
	const { providers } = useContext(SetupContext);
	const { data: dataConnectors, refetch: refetchConnectors } = useQuery('connectors', controller);
	const { data, refetch } = useQuery('hooks', {variables: {include: 'connector'}}, controller);
	const { data: dataActionsOrderCounters } = useQuery('actionsOrderCounters', {variables: {
		code: 'OrderCreate',
		isGroup: true,
		createdAtTo,
	}}, controller);

	const [ hookCreate, { loading: loadingHookCreate } ] = useMutation('hookCreate', controller);
	const [ hookUpdate ] = useMutation('hookUpdate', controller);
	const [ hookDelete ] = useMutation('hookDelete', controller);
	const [ connectorUpdate ] = useMutation('connectorUpdate', controller);
	const { register, handleSubmit, setValue, clearErrors, formState: { errors } } = useForm();

	const [ state, setState ] = useState({
		deleteItem: null,
		createItem: null,
		updateItem: null,
		updateItemAccount: null,
		dataHooksPerAccounts: null,
		dataAccountsDetailPerConnectors: null,
	});

	const onSubmitCreate = values => {
		setState({...state, createItem: values});

		hookCreate({
			variables: {
				...values,
				connectorId: parseInt(values.connectorId),
			}
		}, controller).then(() => {
			refetch();
			// obsoleted / reset();
		}).catch(() => {
			// obsoleted / setError('submit', {message: 'Error: ' + err.message}); // temporal message
		});
	};

	const onSubmitUpdate = values => {
		setState({...state, updateItem: null});

		hookUpdate({
			variables: {...values, id: state.updateItem.id},
		}, controller).then(() => {
			refetch(); // kiss-y overkill
		}).catch(() => {
			// obsoleted / setError('submit', {message: 'Error: ' + err.message}); // temporal message
		});
	};

	const onSubmitDelete = (values, event) => {
		event.preventDefault();

		hookDelete({
			variables: values
		}, controller).then(() => {
			refetch(); // kiss-y overkill
		}).catch(() => {
			// obsoleted / setError('submit', {message: 'Error: ' + err.message}); // temporal message
		});
	};

	const onCancelDelete = () => {
		setState({...state, deleteItem: null});
	};

	const onSubmitUpdateAccount = values => {
		setState({...state, updateItemAccount: null});

		const connector = dataConnectors.connectors.find(item => item.id === state.updateItemAccount.connectorId);
		const accountsDetails = JSON.parse(connector.accountsDetails);
		accountsDetails[state.updateItemAccount.id] = {
			...accountsDetails[state.updateItemAccount.id],
			name: values.name,
		};

		values.name && connectorUpdate({
			variables: {accountsDetails: JSON.stringify(accountsDetails), id: connector.id},
		}, controller).then(() => {
			refetch(); // kiss-y overkill
		}).catch(() => {
			// obsoleted / setError('submit', {message: 'Error: ' + err.message}); // temporal message
		});
	};

	const getConnectorExample = (connector, account) => {
		const reference = providers[connector.providerName].reference;
		const symbols = reference?.symbol?.examples || [];
		const symbolsClass = reference?.symbol?.examplesClass || [];
		const symbolsBrach = reference?.symbol?.examplesBranch || [];
		const symbolsAccount = reference?.symbol?.examplesAccount || [];

		const symbolIndex = Math.max(symbolsBrach.findIndex(item => item == connector.branch), 0);

		return JSON.stringify({
			...(['future', 'option', 'warrant', 'bond'].includes(symbolsClass[symbolIndex]) ? {class: symbolsClass[symbolIndex]} : {}),
			symbol: (symbols[Math.max(symbolsAccount.indexOf(account), symbolIndex)] || 'AAPL').toUpperCase(), quantity: 1, action: 'buy',
		});
	};

	useEffect(() => {
		data && dataConnectors && setState({...state,
			dataHooksPerAccounts: dataConnectors.connectors.reduce((hooks, connector) => {
				connector.accounts.forEach(account => {
					hooks[`${account}@${connector.id}`] = data.hooks.
						filter(hook => hook.account == account && hook.connector.id == connector.id).
						sort((a, b) => a.name > b.name ? 1 : -1); // reserved : to add a.createdAt < b.createdAt?
				});
				return hooks;
			}, {}),
			dataAccountsDetailPerConnectors: dataConnectors.connectors.reduce((accountsDetails, connector) => {
				accountsDetails[connector.id] = connector.accountsDetails ? JSON.parse(connector.accountsDetails) : {};
				return accountsDetails;
			}, {}),
		});
	}, [data, dataConnectors]);

	useEffect(() => { state?.updateItem && setValue('name', state.updateItem.name); }, [state?.updateItem]);
	useEffect(() => { state?.updateItemAccount && setValue('name', state.updateItemAccount.name); }, [state?.updateItemAccount]);

	useEffect(() => () => controller.abort(), []); // abort on unmount

	return data && dataConnectors && state.dataHooksPerAccounts && state.dataAccountsDetailPerConnectors ? (
		<div id="connector">
			<div className="d-flex my-3">
				<div><h3 className="pe-5">Brokers &amp;&nbsp;Webhooks</h3></div>

				{
					dataConnectors?.connectors.length ? (
						<div className="ms-auto">
							<MeConnectorCreate providers={providers} connectors={dataConnectors.connectors} selectedProviderName={selectedProviderName} isCompact={true} />
						</div>
					) : false
				}
			</div>

			{
				!dataConnectors?.connectors.length ? (
					<div>
						<p>Select a broker or exchange to connect:</p>
						<MeConnectorCreate providers={providers} connectors={dataConnectors.connectors} selectedProviderName={selectedProviderName} />
					</div>
				) : false
			}

			{
				dataConnectors?.connectors.length ? (
					dataConnectors.connectors.map(itemConnector =>
						providers[itemConnector.providerName] && itemConnector.accounts.map(itemAccount =>
							<div key={itemAccount} className="mb-4 p-2 p-lg-3 bg-light rounded rounded-3 overflow-hidden">
								<table className="table table-light">
									<thead>
										<tr>
											<td colSpan="4">
												<div className="d-md-flex">
													<div className="pe-2 flex-grow-1">
														<div className="fw-bold">{providers[itemConnector.providerName].title} {itemConnector.branch ? '@' + itemConnector.branch : ''}</div>
														{
															state.updateItemAccount?.connectorId === itemConnector.id && state.updateItemAccount?.id === itemAccount ?
																<div><form onSubmit={handleSubmit(onSubmitUpdateAccount)}><input {...register('name', {required: 'This is required.'})} type="text" id="name" placeholder="Name" className={formControlInvalid(errors?.name, 'form-control form-control-sm d-inline w-auto me-2')} /><button type="submit" className="btn btn-sm btn-outline-primary me-2">Save</button><button type="button" onClick={() => { setState({...state, updateItemAccount: null}); }} className="btn btn-sm btn-outline-secondary">Cancel</button></form></div>
																:
																itemAccount && (() => {
																	const connectorDetails = state.dataAccountsDetailPerConnectors[itemConnector.id] || {};
																	const accountDetails = connectorDetails[itemAccount] || {};

																	const title = accountDetails.dataTitle || itemAccount;
																	const titleSuffix = [accountDetails.name, accountDetails.dataType, accountDetails.dataCurrency].filter(item => !!item).join(', ');

																	return (
																		<div className="hover text-nowrap">
																			{`Account\u00A0${title ? '#' + title : ''}`} <span className="text-capitalize">{titleSuffix ? `(${titleSuffix})` : ''}</span>
																			{ itemConnector.demo || accountDetails.demo ? <span className="badge bg-secondary ms-1">Demo</span> : false }
																			<span className="hover-opacity-100 opacity-0 ps-2"><a href="#" onClick={() => { event.preventDefault(); clearErrors(); setState({...state, updateItem: null, updateItemAccount: {id: itemAccount, name: accountDetails.name, connectorId: itemConnector.id}}); }}>Edit</a></span>
																		</div>
																	);
																})()
														}
														{/*
														<div className="d-flex">
															<div className="d-block d-md-none"><a href="#" onClick={(event) => { event.preventDefault(); !loadingHookCreate && onSubmitCreate({name: 'Hook', connectorId: itemConnector.id, account: itemAccount}); }} role="button" className="text-decoration-none pe-3"><Spinner show={loadingHookCreate && !state.createItem?.test && state.createItem?.connectorId == itemConnector.id && state.createItem?.account == itemAccount} delay={0}>Create webhook</Spinner></a></div>
															<div className="d-block d-md-none"><a href="#" onClick={(event) => { event.preventDefault(); !loadingHookCreate && onSubmitCreate({name: 'Hook', connectorId: itemConnector.id, account: itemAccount, test: true}); }} role="button" className="text-decoration-none pe-3"><Spinner show={loadingHookCreate && state.createItem?.test && state.createItem?.connectorId == itemConnector.id && state.createItem?.account == itemAccount} delay={0}>Create test webhook</Spinner></a></div>
														</div>*/}
													</div>
													<div className="d-flex">
														<div><MeConnectorDelete providers={providers} connector={itemConnector} onSubmit={() => { refetch(); refetchConnectors(); }} /></div>
														<div><a href="#" onClick={event => { event.preventDefault(); !loadingHookCreate && onSubmitCreate({name: 'Hook', connectorId: itemConnector.id, account: itemAccount}); }} role="button" className="text-decoration-none ps-3"><Spinner show={loadingHookCreate && !state.createItem?.test && state.createItem?.connectorId == itemConnector.id && state.createItem?.account == itemAccount} delay={0}>Create webhook</Spinner></a></div>
														<div><a href="#" onClick={event => { event.preventDefault(); !loadingHookCreate && onSubmitCreate({name: 'Hook', connectorId: itemConnector.id, account: itemAccount, test: true}); }} role="button" className="text-decoration-none ps-3"><Spinner show={loadingHookCreate && state.createItem?.test && state.createItem?.connectorId == itemConnector.id && state.createItem?.account == itemAccount} delay={0}>Create test webhook</Spinner></a></div>
													</div>
												</div>
											</td>
										</tr>
									</thead>

									{
										!providers[itemConnector.providerName].status && (
											<tbody>
												<tr>
													<td colSpan="4" className="bg-warning text-center">
														The brokerage is disabled. Any calls to the hooks are be forbidden.
													</td>
												</tr>
											</tbody>
										)
									}

									{
										itemConnector.status == config.connector.status.values.ACTIVE && (

											// nb. keep +1 day, to not to be "behind" the backend condition (see Math.floor...)
											(itemConnector.expiresAt && parseInt(itemConnector.expiresAt) < Date.now() + (['schwab'].includes(itemConnector.providerName) ? 2 + 1 : 5 + 1) * 24 * 3600 * 1000) && (
												<tbody>
													<tr>
														<td colSpan="4" className="bg-warning text-center">
															The connection is {parseInt(itemConnector.expiresAt) < Date.now() ? 'expired' : 'about to expire soon'}, please <MeConnectorCreate providers={{[itemConnector.providerName]: providers[itemConnector.providerName]}} connectors={dataConnectors.connectors} isCompact={true} title="Reconnect" className="btn btn-sm btn-outline-primary" />
														</td>
													</tr>
												</tbody>
											)
										)
									}

									{
										itemConnector.status == config.connector.status.values.INACTIVE && (
											<tbody>
												<tr>
													<td colSpan="4" className="bg-warning text-center">
														The connection is inactive due to connection or authorization issues.
														Try manually to <MeConnectorCreate providers={{[itemConnector.providerName]: providers[itemConnector.providerName]}} isCompact={true} title="Reconnect" className="btn btn-sm btn-outline-primary" />
													</td>
												</tr>
											</tbody>
										)
									}

									{
										itemConnector.providerName === 'tda' && (
											<tbody>
												<tr>
													<td colSpan="4" className="bg-warning">
														<small>As you know, TD Ameritrade and Schwab are merging. As part of their merger, they will be disabling all third party access to the TD Ameritrade API. You should have received a notification from them. At this time, your webhooks will stop working. We will connect to the new Schwab API as soon as we are given access to it. Sorry for any inconvenience this may cause. If you wish to continue auto trading, please consider opening an account at a supported broker.</small>
													</td>
												</tr>
											</tbody>
										)
									}

									{
										state.dataHooksPerAccounts && state.dataHooksPerAccounts[`${itemAccount}@${itemConnector.id}`]?.length ?
											<tbody>
												<tr className="bg-light">
													<td>Name, Link</td>
													<td colSpan="2" className="d-none d-md-table-cell">Signals,&nbsp;24h</td>
													{/* <td>Signals in last 24h</td> */}
													{/* <td></td> */}
												</tr>
												{
													state.dataHooksPerAccounts[`${itemAccount}@${itemConnector.id}`].map(item =>
														<tr key={item.id + item.name}>
															<td>
																{
																	state.updateItem?.id === item.id ?
																		<div><form onSubmit={handleSubmit(onSubmitUpdate)}><input {...register('name', {required: 'This is required.'})} type="text" id="name" placeholder="Name" className={formControlInvalid(errors?.name, 'form-control form-control-sm d-inline w-auto me-2')} /><button type="submit" className="btn btn-sm btn-outline-primary me-2">Save</button><button type="button" onClick={() => { setState({...state, updateItem: null}); }} className="btn btn-sm btn-outline-secondary">Cancel</button></form></div>
																		:
																		<div className="hover text-nowrap">{item.name || 'Noname'} {item.test ? <span className="badge bg-secondary">Test</span> : false} <span className="hover-opacity-100 opacity-0 ps-2"><a href="#" onClick={() => { event.preventDefault(); clearErrors(); setState({...state, updateItem: item, updateItemAccount: null}); }}>Edit</a></span></div>
																}
																<div role="button" onClick={() => setClipboard(getHookUrl(item.shortKey))} className="hover text-nowrap">{getHookUrl(item.shortKey)}<span className="hover-opacity-100 opacity-25 ps-2"><ClipboardIcon /></span></div>
																<div className="d-block d-md-none"><a href="#" onClick={event => { event.preventDefault(); item.test ? onSubmitDelete({id: item.id}, event) : setState({...state, deleteItem: item}); }} role="button" className="text-decoration-none">Delete</a></div>
															</td>
															<td className="d-none d-md-table-cell">{dataActionsOrderCounters?.actionsOrderCounters.find(itemCounter => itemCounter.hookShortKey == item.shortKey)?.counter || '-'}</td>
															<td align="right" className="d-none d-md-table-cell"><a href="#" onClick={event => { event.preventDefault(); item.test ? onSubmitDelete({id: item.id}, event) : setState({...state, deleteItem: item}); }} role="button" className="text-decoration-none">Delete</a></td>
														</tr>
													)
												}
												{
													state.dataHooksPerAccounts[`${itemAccount}@${itemConnector.id}`]?.length && (
														<tr>
															<td colSpan="2" role="button" onClick={() => setClipboard(getConnectorExample(itemConnector), itemAccount)} className="hover">
																<div>Example request:</div><div><code className="alert alert-primary d-inline-block p-1 mb-0">{getConnectorExample(itemConnector, itemAccount)}</code><span className="hover-opacity-100 opacity-25 ps-2"><ClipboardIcon /></span></div>
																<div className="d-block d-md-none mt-1"><a href={'/me/documentation/' + itemConnector.providerName} role="button" className="text-decoration-none">Webhook Docs</a></div>
															</td>
															<td colSpan="2" align="right" className="d-none d-md-table-cell"><a href={'/me/documentation/' + itemConnector.providerName} role="button" className="text-decoration-none">Webhook Docs</a></td>
														</tr>
													)
												}
											</tbody>
											:
											<tbody>
												<tr className="bg-light">
													<td colSpan="4" className="text-center"><div className="mt-2">No webhooks created</div></td>
												</tr>
											</tbody>
									}
								</table>
							</div>
						)
					)
				) : false
			}

			<Modal show={!!state.deleteItem} onHide={onCancelDelete}>
				<ModalHeader title="Confirmation" onHide={onCancelDelete}></ModalHeader>
				{
					state.deleteItem && [state.deleteItem].map(item =>
						<ModalBody key={item.id}>
							{/* TODO the "calledAt" attribute been dropped. use the actions to get last call timestamp */}
							<p>You are about to remove a webhook {item.name || getHookUrl(item.shortKey)}{item.calledAt ? ' (last triggered at ' + new Date(Math.round(item.calledAt / 1000) * 1000).toLocaleString() + ')' : ''}.</p>

							<p>Removing a webhook means that all future requests to its URL will fail and won&apos;t execute any orders.
							You won&apos;t be able to restore your webhook, but you can create a new one.</p>

							<p>Are you sure you want to proceed?</p>
						</ModalBody>
					)
				}
				<ModalFooter>
					<button type="button" onClick={() => { setState({...state, deleteItem: null}); }} className="btn btn-secondary">Cancel</button>
					<button type="button" onClick={event => { onSubmitDelete({id: state.deleteItem.id}, event); setState({...state, deleteItem: null}); }} className="btn btn-danger">Confirm</button>
				</ModalFooter>
			</Modal>
		</div>
	) : false;
}

export default withRouter(MeConnector);
