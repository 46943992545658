import { gql, ApolloClient, HttpLink, InMemoryCache,
	useLazyQuery as _useLazyQuery,
	useMutation as _useMutation,
	useQuery as _useQuery,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import config from '../config';

const GQL = {
	signup: gql`
		mutation Signup(
			$email: String!, $password: String!,
			$firstName: String, $lastName: String,
			$referrer: String,
			$promotionCode: String,
			$stripePriceId: String,
			$baseUrl: String!
		) {
			signup(
				email: $email, password: $password,
				firstName: $firstName, lastName: $lastName,
				referrer: $referrer,
				promotionCode: $promotionCode,
				stripePriceId: $stripePriceId,
				baseUrl: $baseUrl
			) {
				id
				group
				email
				firstName
				lastName
				emailConfirmed
			}
		}
	`,
	signin: gql`
		mutation Signin($email: String!, $password: String!, $passcode: String) {
			signin(email: $email, password: $password, passcode: $passcode) {
				token
				expires
			}
		}
	`,
	signout: gql`
		mutation Signout {
			signout
		}
	`,
	user: gql`
		query User {
			user {
				id
				group
				email
				firstName
				lastName
				emailConfirmed

				reamazeAuthKey
			}
		}
	`,
	userUpdate: gql`
		mutation UserUpdate(
			$email: String,
			$firstName: String,
			$lastName: String,
		) {
			userUpdate(
				email: $email,
				firstName: $firstName,
				lastName: $lastName,
			) {
				id
				group
				email
				firstName
				lastName
				emailConfirmed
			}
		}
	`,
	userPasscode: gql`
		query UserPasscode {
			userPasscode {
				secret
				uri
				qr
			}
		}
	`,
	userPasscodeUpdate: gql`
		mutation UserPasscodeUpdate($secret: String!, $passcode: String!) {
			userPasscodeUpdate(secret: $secret, passcode: $passcode) {
				id
				group
				email
				firstName
				lastName
				emailConfirmed
			}
		}
	`,
	// reserved
	// userPasswordUpdate: gql`
	// 	mutation UserPasswordUpdate($token: String!, $password: String!, $passcode: String) {
	// 		userPasswordUpdate(token: $token, password: $password, passcode: $passcode)
	// 	}
	// `,
	authPasswordEstimate: gql`
		mutation AuthPasswordEstimate($password: String) {
			authPasswordEstimate(password: $password)
		}
	`,
	authPasswordReset: gql`
		mutation AuthPasswordReset($token: String!, $password: String!, $passcode: String) {
			authPasswordReset(token: $token, password: $password, passcode: $passcode) {
				token
				expires
			}
		}
	`,
	authPasswordResetRequest: gql`
		mutation AuthPasswordResetRequest($email: String!, $baseUrl: String) {
			authPasswordResetRequest(email: $email, baseUrl: $baseUrl)
		}
	`,
	authEmailConfirm: gql`
		mutation AuthEmailConfirm($token: String!) {
			authEmailConfirm(token: $token)
		}
	`,
	account: gql`
		query Account {
			account {
				id
				type
				credits
				recurringCredits
				recurringPeriodCredits
				recurringPeriodEnd
				metadata
				createdAt
				updatedAt
			}
		}
	`,
	accountUpdate: gql`
		mutation accountUpdate($platformName: String) {
			accountUpdate(platformName: $platformName) {
				id
			}
		}
	`,
	accountPay: gql`
		mutation AccountPay($productId: Int!, $paymentMethodId: String!, $interval: String, $coupon: String) {
			accountPay(productId: $productId, paymentMethodId: $paymentMethodId, interval: $interval, coupon: $coupon) {
				id
			}
		}
	`,
	accountCoupon: gql`
		query AccountCoupon($id: String!) {
			accountCoupon(id: $id) {
				id
				valid
				percent_off
				amount_off
			}
		}
	`,
	accountInvoice: gql`
		query AccountInvoice($id: String!) {
			accountInvoice(id: $id) {
				id
				invoice_pdf
			}
		}
	`,
	accountPaymentSession: gql`
		query AccountPaymentSession {
			accountPaymentSession {
				id
				url
			}
		}
	`,
	accountSignupPaymentSession: gql`
		query AccountSignupPaymentSession($email: String!, $stripePriceId: String!, $successUrl: String, $cancelUrl: String) {
			accountSignupPaymentSession(email: $email, stripePriceId: $stripePriceId, successUrl: $successUrl, cancelUrl: $cancelUrl) {
				id
				url
				clientSecret
			}
		}
	`,
	accountPaymentMethods: gql`
		query AccountPaymentMethodList($type: String) {
			accountPaymentMethods(type: $type) {
				id
				type
				card {
					brand
					country
					last4
				}
			}
		}
	`,
	action: gql`
		query Action($id: Int!) {
			action(id: $id) {
				id
				code
				address
				metadata
				createdAt
			}
		}
	`,
	// using Float as Long/BigInt, mostly
	actions: gql`
		query Actions($code: String, $createdAtFrom: Float, $search: String) {
			actions(code: $code, createdAtFrom: $createdAtFrom, search: $search) {
				id
				code
				address
				metadata
				createdAt
			}
		}
	`,
	// using Float as Long/BigInt, mostly
	actionsOrderCounters: gql`
		query ActionsOrderCounters($isTest: Boolean, $isGroup: Boolean, $createdAtTo: Float!) {
			actionsOrderCounters(isTest: $isTest, isGroup: $isGroup, createdAtTo: $createdAtTo) {
				counter
				counterTest
				hookShortKey
			}
		}
	`,
	// using Float as Long/BigInt, mostly
	actionsOrderStats: gql`
		query ActionsOrderStats($interval: String, $createdAtFrom: Float, $createdAtTo: Float, $providerName: String) {
			actionsOrderStats(interval: $interval, createdAtFrom: $createdAtFrom, createdAtTo: $createdAtTo, providerName: $providerName) {
				counter
				counterError
				createdAt
			}
		}
	`,
	providers: gql`
		query Providers {
			providers
		}
	`,
	// obsoleted
	// providers: gql`
	// 	query Providers {
	// 		providers {
	// 			name
	// 			title
	// 			status
	// 			reference
	// 			authentication {
	// 				type
	// 				demo
	// 				credentials
	// 				referrerBase
	// 				redirectUrl
	// 				help
	// 			}
	// 		}
	// 	}
	// `,
	connector: gql`
		query Connector($id: Int) {
			connector(id: $id) {
				id
				providerName
				accounts
				accountsDetails
				demo
				status
				createdAt
				expiresAt
			}
		}
	`,
	connectors: gql`
		query Connectors {
			connectors {
				id
				providerName
				accounts
				accountsDetails
				branch
				demo
				status
				createdAt
				expiresAt
			}
		}
	`,
	connectorCreate: gql`
		mutation ConnectorCreate($providerName: String!, $demo: Boolean) {
			connectorCreate(providerName: $providerName, demo: $demo) {
				type
				credentials
				referrerBase
				requestUrl
				redirectUrl
				help
				metadata
			}
		}
	`,
	connectorActivate: gql`
		mutation ConnectorActivate($providerName: String!, $credentials: [String]) {
			connectorActivate(providerName: $providerName, credentials: $credentials) {
				id
				providerName
				accounts
				status
				createdAt
			}
		}
	`,
	connectorUpdate: gql`
		mutation ConnectorUpdate($id: Int!, $accountsDetails: String) {
			connectorUpdate(id: $id, accountsDetails: $accountsDetails) {
				id
				providerName
				accounts
				accountsDetails
				status
				createdAt
				expiresAt
			}
		}
	`,
	connectorDelete: gql`
		mutation ConnectorDelete($id: Int!) {
			connectorDelete(id: $id) {
				id
				providerName
				accounts
			}
		}
	`,
	hook: gql`
		query Hook($id: Int!) {
			hook(id: $id) {
				id
				key
				shortKey
				name
				account
				status
				test
				createdAt
				connector {
					id
					providerName
					accounts
					status
				}
			}
		}
	`,
	hooks: gql`
		query Hooks($connectorId: Int) {
			hooks(connectorId: $connectorId) {
				id
				key
				shortKey
				name
				account
				status
				test
				createdAt
				connector {
					id
					providerName
					accounts
					status
				}
			}
		}
	`,
	hookCreate: gql`
		mutation HookCreate($connectorId: Int!, $account: String, $name: String, $test: Boolean) {
			hookCreate(connectorId: $connectorId, account: $account, name: $name, test: $test) {
				id
				key
				shortKey
				name
				account
				status
				test
				createdAt
			}
		}
	`,
	hookUpdate: gql`
		mutation HookUpdate($id: Int!, $name: String) {
			hookUpdate(id: $id, name: $name) {
				id
				key
				shortKey
				name
				account
				status
				test
				createdAt
			}
		}
	`,
	hookDelete: gql`
		mutation HookDelete($id: Int!) {
			hookDelete(id: $id) {
				id
				key
				shortKey
				name
				account
			}
		}
	`,
	orderPreview: gql`
		mutation OrderPreview($connectorId: Int!, $account: String, $data: String!) {
			orderPreview(connectorId: $connectorId, account: $account, data: $data) {
				id,
				symbol,
				action,
				quantity,
				price,
				status,
				statusDescription,
			}
		}
	`,
	product: gql`
		query Product($id: Int!) {
			product(id: $id) {
				id
				type
				name
				credits
				price
				metadata
			}
		}
	`,
	products: gql`
		query Products {
			products {
				id
				type
				name
				credits
				price
				metadata
			}
		}
	`,
	adminReferrerCreate: gql`
		mutation AdminReferrerCreate($name: String, $providerName: String, $credits: Int!) {
			adminReferrerCreate(name: $name, providerName: $providerName, credits: $credits)
		}
	`,
	adminReferrerDelete: gql`
		mutation AdminReferrerDelete($referrer: String!) {
			adminReferrerDelete(referrer: $referrer)
		}
	`,
	adminAccountReward: gql`
		mutation AdminAccountReward($email: String!, $credits: Int!) {
			adminAccountReward(email: $email, credits: $credits)
		}
	`,
	adminReferrerStatsReport: gql`
		query AdminReferrerStatsReport {
			adminReferrerStatsReport
		}
	`,
	// obsoleted
	// adminUserStatsReport: gql`
	// 	query AdminUserStatsReport {
	// 		adminUserStatsReport
	// 	}
	// `,
	// obsoleted
	// adminProviderStatsReport: gql`
	// 	query AdminProviderStatsReport {
	// 		adminProviderStatsReport
	// 	}
	// `,
};

// nb: buildFederatedSchema hides (origin and meaningful) error messages.
export const graph = new (class Graph {
	constructor(uri) {
		const httpLink = new HttpLink({uri}); // TODO: 'X-Remote-Address' could be added here

		const authLink = setContext((_, { headers }) => {
			const token = localStorage.getItem('token'); // to config?
			const proxiedUserEmail = sessionStorage.getItem('proxiedUserEmail'); // to config?

			// obsoleted
			// if (token !== this._token) {
			// 	this._token = token;
			// }

			// set auth token
			return {
				headers: {
					...headers,
					...(proxiedUserEmail ? {'X-User': proxiedUserEmail} : {}),
					'authorization': token ? `Bearer ${token}` : '',
				}
			};
		});

		// experimental
		const errorLink = onError(({ graphQLErrors, networkError }) => {
			if (graphQLErrors) {
				for (const err of graphQLErrors) {
					switch (err.extensions?.code) {
						case 'UNAUTHENTICATED':
							this.onUnauthenticatedError && this.onUnauthenticatedError();
					}
				}
			}

			if (networkError) {
				console.log(`Network error: ${networkError}`); // temporal?
			}
		});

		this._client = new ApolloClient({
			cache: new InMemoryCache(),
			link: errorLink.concat(authLink.concat(httpLink)),
		});
	}

	client() {
		return this._client;
	}

	useQuery(query, options) {
		return _useQuery(query, {client: this._client, ...options});
	}

	useLazyQuery(query, options) {
		return _useLazyQuery(query, {client: this._client, ...options});
	}

	useMutation(mutation, options) {
		return _useMutation(mutation, {client: this._client, ...options});
	}

	// optional handlers:
	// onUnauthenticatedError

})(config.server.baseGraph);

function getOptions(optionsOrController, controllerOrUndefined) {
	return optionsOrController instanceof AbortController ?
		{controller: optionsOrController} : {...optionsOrController, signal: controllerOrUndefined?.signal};
}

export function useQuery(gqlOrName, optionsOrController, controllerOrUndefined) {
	return graph.useQuery(GQL[gqlOrName] || gqlOrName, getOptions(optionsOrController, controllerOrUndefined));
}

export function useLazyQuery(gqlOrName, optionsOrController, controllerOrUndefined) {
	return graph.useLazyQuery(GQL[gqlOrName] || gqlOrName, getOptions(optionsOrController, controllerOrUndefined));
}

export function useMutation(gqlOrName, optionsOrController, controllerOrUndefined) {
	return graph.useMutation(GQL[gqlOrName] || gqlOrName, getOptions(optionsOrController, controllerOrUndefined));
}

// temporal, 240220
// (async () => {
// 	const connectorId = 256;

// 	const response = await fetch(config.server.base + '/api/subscriptions', {
// 		body: JSON.stringify({connectorId}),
// 		headers: {
// 			'Content-Type': 'application/json',
// 			'Authorization': 'Bearer ' + localStorage.getItem('token'),
// 		},
// 		method: 'post',
// 	});

// 	const { token } = await response.json();

// 	const evtSource = new EventSource(config.server.base + '/api/subscriptions/' + token, {
// 		withCredentials: true,
// 		headers: {'Authorization': 'Bearer TEST'},
// 	});

// 	evtSource.onmessage = event => {
// 		console.log('!!!!event', event.data);
// 	};
// })();

export default graph;
